import Vue from 'vue'
import Vuex from 'vuex'
// import http from "@/api/http"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin:false,
    subUnitSize: 'mini',
    roleTypeObj: {
      1: '管理员',
      2: '员工'
    },
    unit: [],
    invoiceCompany: [], // 开票公司
    access: {
      company: '',
      insurance: '',
      employee: '',
      finance: ''
    },
    icId: '',
    routes:[]
  },
  mutations: {
    setUnit: (state, payload) => state.unit = payload,
    setInvoiceCompany: (state, payload) => state.invoiceCompany = payload,
    setAccess(state, payload) {
      state.access = payload.access
      state.icId = payload.invoice_company_id
    },
    setIsLogin(state, payload){
      state.isLogin = payload;
    },
    setRouter(state, payload) {
      state.routes = payload
    }
  },
  actions: {
    // setUnit: (injectee, payload) => injectee("setUnit", payload),
    // setInvoiceCompany: (injectee, payload) => injectee("setInvoiceCompany", payload),
    // login({commit}){
    //   console.log(commit)
    //   http.post('/admin/admin/login', { account: '18979158400',
    //   password: '123456'}).then(res =>{
    //     if(res.code === 1) {
    //       localStorage.setItem('token', res.token)
    //       commit('setAccess', res)
    //     } else {
    //       this.$message.error(res.msg)
    //     }
       
    //     // commit('setRouter',this.routes)
        

    //         // this.$router.push('/company/index')

    //     // this.routes = this.$router.options.routes.slice(2, this.$router.options.routes.length - 1)
        
    //     // console.log(this.routes);
    //     // console.log(`开票公司id：${this.$store.state.icId}`)
    //     // let routerArr = this.routes[3].children

    //     // if(this.$store.state.icId === 0) {
    //     //   return this.routes[3].children = this.routes[3].children.filter(item => item.meta.role !== 0);
    //     // } else if(this.$store.state.icId !== 0) {
    //     //   return this.routes[3].children = this.routes[3].children.filter(item => item.meta.role !== 1);
    //     // }
    //   })
    // }
  },
  getters: {
    unit: state => state.unit,
    invoiceCompany: state => state.invoiceCompany,
    routesArr: state => state.routes,
  },
  modules: {
  },
})
