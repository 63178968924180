<template>
  <el-aside width="200px">
    <el-menu
      class="menu__wrap el-menu-vertical-demo"
      router
      :default-active="$route.path"
      :collapse="isCollapse"
      :unique-opened="false"
      text-color="#333"
      active-text-color="#B42020"
    >
      <el-submenu
        v-for="item in routes"
        :key="item.name"
        :index="item.path">
        <template>
          <template slot="title">
            <span>{{ item.meta.title }}</span>
          </template>
          <el-menu-item-group>
            <template v-for="child in item.children">
              <el-menu-item
                :key="child.name"
                :index="child.path"
              >{{ child.meta.title }}
              </el-menu-item>
            </template>
          </el-menu-item-group>
        </template>
      </el-submenu>
    </el-menu>

  </el-aside>
</template>
<script>
export default {
  name: 'LayoutNaviBar',
  props: ['isCollapse'],
  data() {
    return {
      routes: [],
    }
  },
  mounted() {
    this.routes = this.$router.options.routes.slice(2, this.$router.options.routes.length - 1)
  }
}
</script>
<style scoped lang="scss">
.menu__wrap {
  height: 100%;
  box-sizing: border-box;
  &::v-deep {
    .el-menu-item-group__title {
      display: none;
    }
  }
  .menu-icon {
    margin-right: 10px;
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu-item:hover, .el-menu-item:focus {
  background-color:#fff6f6 !important
}
</style>
