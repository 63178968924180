<template>
  <el-header class="shadow page-header__wrap">
    <div class="title">
      <span class="site">当前位置：</span>
      {{ $route.meta.parent }}
      <span> > </span>
      {{ $route.meta.title }}
    </div>
    <div class="refresh" >
      <el-button  icon="el-icon-refresh" size="small" @click="handleRefresh">刷新</el-button>
    </div>
  </el-header>
</template>

<script>
export default {
  name: 'PageHeader',
  data() {
    return {

    }
  },
  methods: {
    //刷新;
    handleRefresh() {
      window.location.reload();
    }
  }
}
</script>
<style scoped>
.site {
  color: #898989;
}
</style>