import Vue from 'vue'
import VueRouter from 'vue-router'
import {commonRouter} from "./routes"
import {getStorage} from '@/storage'
import store from "@/store/index"
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: commonRouter
})

router.beforeEach((to, from, next) => {
  console.log(to)
  if(getStorage('token')){
    to.path === "/login" ? next('/home') : next()
  } else {
    to.path === "/login" ? next() : next('/login')
  }
})

export default router
