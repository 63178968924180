import Layout from "@/layout/Container.vue"

export const commonRouter = [
  {
    path: '/',
    redirect: '/article/list'
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: "登录",
    },
    component: () => import("@/views/login")
  },
  {
    path: '/article',
    name: 'Article',
    meta: {
      title: "文章管理",
    },
    component: Layout,
    children: [
      {
        path: '/article/list',
        name: 'ArticleList',
        meta: {
          parent: '文章管理',
          title: '文章列表'
        },
        component: () => import("@/views/article/index")
      }
    ]
  },
  {
    path: "/banner",
    name: "Banner",
    meta: {
      title: "轮播图管理",
    },
    component: Layout,
    children: [
      {
        path: "/banner/list",
        name: "BannerList",
        meta: {
          parent: '轮播图管理',
          title: '轮播图列表'
        },
        component: () => import("@/views/banner/index")
      }
    ]
  },
  {
    path: '*',
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import("@/views/404")
  }
]



